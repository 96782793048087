import React, { useState, useEffect } from "react";
import Img from "../assets/images/Group 3.png";
import Img1 from "../assets/images/address.png";
import drowdown from "../assets/icons/dropdown.png";
import { useApi } from "../context/ApiContext";
import SEO from "../components/SEO";
import { useGetPaymentMethodsQuery } from "../Slices/paymentServiceSlice";
import Spinner from "../components/preloader/Spinner";
import { useLocation } from "react-router-dom";
import TransactionModal from "../components/TransactionModal";
import { formatNumberAsCurrency, toTitleCase } from "../Slices/utilSlice";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
const Donate = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const { data: paymethods } = useGetPaymentMethodsQuery();
  const [partnerType, setPartnerType] = useState("");
  const [isPartner, setIsPartner] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const { getData, postData, loading, error } = useApi();
  const [data, setData] = useState([]);
  const [partershipAmount, setPartershipAmount] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [referenceDetails, setReferenceDetails] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [referrerCode, setReferrerCode] = useState("");
  const [clickedIndex, setClickedIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const location = useLocation();

  const dropdownOptions = [
    { value: "Donor", label: "Donate" },
    { value: "Partner", label: "Partner" },
  ];

  const handleOptionSelect = (option) => {
    setPartnerType(option);
    setIsPartner(option === "Partner");
    setIsDropdownOpen(false); // Close dropdown when an option is selected
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setEmail(value); // Update email value

    // Check if the email is valid or empty
    if (value === "" || emailRegex.test(value)) {
      setEmailError(""); // Clear error
    } else {
      setEmailError("Please enter a valid email address."); // Show error
    }
  };
  const handleAmountClick = (amount) => {
    const amountAsString = String(amount);
    setSelectedAmount(amountAsString);
    setCustomAmount("");
  };

  const handleCustomAmountChange = (e) => {
    setCustomAmount(e.target.value);
    setSelectedAmount(null);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        amount: selectedAmount || customAmount, // Selected or custom amount
        methodInUse: paymentMethod,
        donationType: "donation",
        anonymous: isAnonymous,
      };

      // Include user meta information if not anonymous
      if (!isAnonymous) {
        payload.metaInformation = {
          email,
          donorFirstName: firstName,
          donorLastName: lastName,
        };
      }

      // Make the POST request via context
      const response = await postData(
        "/paymentService/payment/initiatePayment",
        payload
      );

      setIsLoading(false);
      window.location.href = `${response.data.authorizationUrl}`;
      // Show thank-you modal
      setShowThankYouModal(true);

      // Reset form fields
      setSelectedAmount(null);
      setCustomAmount("");
      setPaymentMethod("");
      setPartnerType("");
      setIsAnonymous(false);
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (err) {
      console.error("Error submitting donation:", err);
      toast.error(err?.data?.message || err.message);
      setIsLoading(false);
    }
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        amount: selectedAmount || customAmount, // Selected or custom amount
        methodInUse: paymentMethod,
        donationType: "partnership",
        anonymous: isAnonymous,
        metaInformation: {
          email,
          donorFirstName: firstName,
          donorLastName: lastName,
          referrerCode,
        },
      };

      // Make the POST request via context
      const response = await postData(
        "/paymentService/payment/initiatePayment",
        payload
      );

      setIsLoading(false);
      window.location.href = `${response.data.authorizationUrl}`;

      // Reset form fields
      setSelectedAmount(null);
      setCustomAmount("");
      setPaymentMethod("");
      setPartnerType("");
      setIsAnonymous(false);
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (err) {
      console.error("Error submitting donation:", err);
      toast.error(err?.data?.message || err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymethods) {
      setData(paymethods.data);
    }
  }, [paymethods]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getData(
          "/userService/referrals/listPartnershipAmounts"
        );
        setPartershipAmount(result.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const donationAmount = selectedAmount || customAmount; // Use selected or custom amount
        // Make GET request with donationAmount as a query parameter
        const result = await getData(
          `/userService/referrals/getReferralCodesForRegistration?donationAmount=${donationAmount}`
        );

        setReferralCode(result.data);
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };

    fetchData();
  }, [selectedAmount, customAmount]);

  // Get Reference Details
  const fetchReferenceData = async (data) => {
    let attempts = 0;
    const maxAttempts = 3;
  
    // Function to fetch the data
    const fetchData = async () => {
      try {
        // Make GET request with reference as a query parameter
        const result = await getData(
          `/paymentService/payment/getPaymentUpdate?reference=${data}`
        );
  
        setReferenceDetails(result.data);
        
        // If payment is completed, stop further attempts and show thank-you modal
        if (result.data.status === "paymentCompleted") {
          setShowThankYouModal(true);
          clearInterval(intervalId);
        }
  
        attempts += 1;
  
        // Stop the interval after maxAttempts
        if (attempts >= maxAttempts) {
          clearInterval(intervalId);
        }
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };
  
    // Set an interval to call the fetchData function every second
    const intervalId = setInterval(fetchData, 1000);
  };
  
  // Extract the reference parameter from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referenceValue = queryParams.get("reference"); // Get 'reference' from query params
    if (referenceValue) {
      fetchReferenceData(referenceValue);
    }
  }, [location]);
  return (
    <div>
      <SEO
        title="Donate"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      {/* Hero Image Section */}
      <div className="relative">
        <img
          src={Img}
          alt="Tomae"
          className="top-[50%] w-full h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] w-[80%] md:w-[480px] lg:w-[580px] h-[58px] font-inter font-semibold text-[30px] sm:text-[60px] leading-[58px] tracking-[-0.03em] text-center z-10">
          {isPartner ? "Become a Partner" : "Get Involved"}
        </h1>
      </div>

      {/* Become a Partner Section */}
      <div className="lg:px-6 max-w-full mx-auto bg-[#E4E4E4] rounded-[20px] h-auto mb-10">
        <div className="flex justify-center mb-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-screen-xl lg:px-4">
            {/* Left Column: Become a Partner */}
            <div className="p-5 lg:p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <h1 className="mb-4 text-[#000]  w-full sm:w-[315px] rounded-lg font-semibold text-[20px] sm:text-[32px] leading-[24px]">
                Become a Partner
              </h1>
              <p className="mt-2 text-[#212121] max-w-full sm:max-w-[580px] font-inter font-normal text-base leading-6 text-left">
                If you’re passionate about our mission and ready to take it a
                step further, consider becoming a partner. <br /> As a partner,
                you not only support BOAF through donations, but you also help
                spread the word about our work.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-left">
                As a Partner, You Will:
              </h1>
              <div>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Donate:{" "}
                  </span>{" "}
                  Contribute any amount to support our initiatives.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Promote:{" "}
                  </span>{" "}
                  Share our mission with your friends, family, and social
                  networks.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                  <span className="text-[#212121] font-inter font-bold text-lg leading-6">
                    Earn Rewards:{" "}
                  </span>{" "}
                  For every three friends you invite to donate, you'll receive a
                  financial reward of ₦12,500 or more depending on the project
                  supported.
                </p>
                <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                  Plus, you’ll be instrumental in building a culture of giving
                  and community support.
                </p>
              </div>
            </div>

            {/* Right Column: Become a Donor */}
            <div className="p-5 lg:p-10 space-y-4 bg-[#E4E4E4] rounded-tr-[20px] border border-solid">
              <h1 className="mb-4 text-[#000]  w-full sm:w-[315px] rounded-[18px] font-semibold text-[20px] sm:text-[32px] leading-[24px]">
                Become a Donor
              </h1>
              <p className="mt-2 text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                As a donor, you have the power to make a significant impact on
                the lives of women and children in our community. Your generous
                contributions enable BOAF to develop transformative initiatives,
                including housing solutions, feeding programs, and empowerment
                workshops.
              </p>
              <h1 className="text-[#000000] font-inter font-medium text-xl leading-[24px] text-left">
                Ways to Donate:
              </h1>
              <p className="text-[#212121] w-full sm:w-[580px] font-inter font-normal text-base leading-6 text-left">
                <span className="text-[#000000] font-inter font-medium text-xl leading-[24px]">
                  One-Time Donation:{" "}
                </span>{" "}
                Make an impact today with a single gift.
                <br />
                <span>Bi-weekly or Monthly Giving: </span> Join our community of
                monthly supporters to make a lasting difference over time.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Image and Text Section */}
      <div className="flex justify-center items-center mb-[80px]">
        {/* Main Section: Two-Column Grid */}
        <div className="flex flex-col lg:flex-row justify-center gap-10 w-full px-4">
          {/* Left Column: Empowering Communities */}
          <div className="p-4 lg:p-[70px] w-full lg:w-[755px] bg-[#E4E4E4] border border-[#E4E4E4] shadow-[12px_12px_23.1px_0px_rgba(0,0,0,0.1)] rounded-tr-[20px]">
            <h1 className="font-inter font-semibold text-[20px] leading-[26px] text-[#252A34] mb-6">
              Become part of this great mission with your support. Help us put a
              smile on their faces. (Minimum of ₦5,000)
            </h1>

            {/* Dropdown for Selecting Partner Type */}
            <div className="mt-6 relative">
              <button
                onClick={handleDropdownToggle}
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2] focus:border-[#00CED2] bg-[#EDF7F5] text-left flex justify-between items-center"
              >
                <span className="text-base text-[#212121]">
                  {partnerType
                    ? dropdownOptions.find(
                        (option) => option.value === partnerType
                      )?.label
                    : "Choose our partnership option"}
                </span>

                <img
                  src={drowdown}
                  alt="dropdown icon"
                  className="w-[15px] h-[10px] ml-2"
                />
              </button>

              {isDropdownOpen && (
                <ul className="absolute right-0 top-[35px] z-10 w-[223px] bg-[#EDF7F5] border border-gray-300 rounded-lg mt-2 shadow-lg">
                  {dropdownOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleOptionSelect(option.value)}
                      className="p-4 hover:bg-[#00CED2] hover:text-white cursor-pointer"
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {partnerType && (
              <div className="mt-[20px]">
                {isPartner ? (
                  <form onSubmit={handlePartnerSubmit} className="space-y-3">
                    {/* Partnership Amount Buttons */}
                    <div className="flex gap-4 flex-wrap ">
                      {partershipAmount && partershipAmount.length > 0 ? (
                        partershipAmount.map((amount, index) => (
                          <button
                            key={index}
                            className={`w-[96px] py-3 text-[#252A34B2]  border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center ${
                              selectedIndex === index
                                ? "bg-[#00CED2] text-white"
                                : "hover:bg-[#00CED2] hover:text-white   bg-[#F1F1F1]"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedIndex(index);
                              setClickedIndex(null);
                              handleAmountClick(amount);
                            }} // Handle the click for each amount
                          >
                            ₦{amount.toLocaleString()}
                          </button>
                        ))
                      ) : (
                        <p>No partnership amounts available</p>
                      )}
                    </div>

                    <p>
                      To be a partner, click on any of the links of already
                      registered partners below to continue.
                    </p>

                    {/* Referrals Buttons */}

                    <div className="w-full space-y-4">
                      <div className="space-y-2">
                        {referralCode && referralCode.length > 0 ? (
                          referralCode.map((code, index) => (
                            <button
                              key={index}
                              className={`w-full lg:w-[423px] py-3 border border-[#787B8280] 
                                text-[#212121] font-bold rounded-lg font-inter text-base 
                                  leading-7 text-center 
                                  ${
                                    clickedIndex === index
                                      ? "bg-[#00CED2] text-white"
                                      : "hover:bg-[#00CED2] hover:text-white   bg-[#FFFFFF]"
                                  }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setClickedIndex(index);
                                setReferrerCode(code.referral_code);
                              }} // Handle the click for each amount
                            >
                              {code.referral_code}
                            </button>
                          ))
                        ) : (
                          <p>No Referral code available</p>
                        )}
                      </div>

                      <div className="w-full lg:w-[423px] h-0 border-t-2 border-gray-300 rounded-full "></div>

                      <div className="w-full lg:w-[423px] flex flex-col gap-2">
                        <motion.div
                          initial={{ y: -30, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.4 }}
                          className="flex gap-x-2 items-center bg-[#FEF6E7] p-1 rounded-lg "
                        >
                          <svg
                            width="60"
                            height="61"
                            viewBox="0 0 60 61"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-8 h-8"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.27042 40.9442L24.0483 11.5114C26.5581 6.82954 33.4419 6.82953 35.9517 11.5114L51.7296 40.9441C54.0614 45.294 50.8189 50.5 45.7779 50.5H14.2221C9.18106 50.5 5.93861 45.294 8.27042 40.9442ZM30 15.5C31.3807 15.5 32.5 16.6193 32.5 18V35.5C32.5 36.8807 31.3807 38 30 38C28.6193 38 27.5 36.8807 27.5 35.5V18C27.5 16.6193 28.6193 15.5 30 15.5ZM26.875 42.375C26.875 44.1009 28.2741 45.5 30 45.5C31.7259 45.5 33.125 44.1009 33.125 42.375C33.125 40.6491 31.7259 39.25 30 39.25C28.2741 39.25 26.875 40.6491 26.875 42.375Z"
                              fill="#F5B546"
                            />
                          </svg>
                          <p className="text-xs sm:text-sm">
                            Enter your Name as registered with your bank
                          </p>
                        </motion.div>
                        <input
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          className="bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className=" bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          value={email}
                          onChange={handleEmailChange}
                          className={`bg-[#EDF7F5] px-4 py-3 border ${
                            emailError ? "border-red-500" : "border-gray-300"
                          } rounded-lg focus:outline-none focus:ring-2 ${
                            emailError
                              ? "focus:ring-red-500"
                              : "focus:ring-[#00CED2]"
                          }`}
                        />
                        {emailError && (
                          <p className="text-red-500 text-sm mt-1">
                            {emailError}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Choose a Payment Method  */}
                    <div className="mt-8">
                      <h1 className="text-[#252A34] font-inter font-semibold text-base leading-7 mb-4">
                        Choose a Payment Method
                      </h1>

                      <div className="space-y-2">
                        {data && data.length > 0 ? (
                          data.map((payment, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handlePaymentMethodChange(payment.name)
                              }
                              className={`flex gap-6 items-center justify-between cursor-pointer w-full lg:w-[424px] p-4 border rounded-lg ${
                                paymentMethod === payment.name
                                  ? " text-white"
                                  : " text-[#252A34]"
                              } hover:bg-[#00CED2]/10 bg-[#EDF7F5] hover:text-white`}
                            >
                              <div className="flex flex-row items-center gap-4">
                                <img
                                  src={payment.logo_url}
                                  alt={payment.name}
                                  className="w-[36px] h-[32px]"
                                />
                                <span className="text-[#252A34] font-bold">
                                  {toTitleCase(payment.name)}
                                </span>
                              </div>

                              <div
                                className={`w-[20px] h-[20px] rounded-full border-2 ${
                                  paymentMethod === payment.name
                                    ? "bg-white border-[#00CED2]"
                                    : "bg-transparent border-[#252A34]"
                                } flex items-center justify-center`}
                              >
                                {paymentMethod === payment.name && (
                                  <div className="w-[12px] h-[12px] bg-[#00CED2] rounded-full"></div>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>No payment methods available</p>
                        )}
                      </div>
                    </div>

                    <button
                      className="mt-4 w-full lg:w-[423px] h-[50px] bg-[#00CED2] text-[#fff] rounded-[15px] font-semibold text-lg"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        <span className="flex items-center justify-center gap-x-1">
                          Proceed as a Partner
                        </span>
                      )}
                    </button>
                  </form>
                ) : (
                  <div>
                    {/* Donation Form */}
                    <form
                      className="flex flex-col items-start space-y-4"
                      onSubmit={handleSubmit}
                    >
                      {/* Amount Selection Buttons */}
                      <div className="flex flex-row gap-2 flex-wrap">
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick("5000")} // Set the amount to N5,000
                        >
                          ₦5,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick("50000")} // Set the amount to N50,000
                        >
                          ₦50,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick("100000")} // Set the amount to N100,000
                        >
                          ₦100,000
                        </button>
                        <button
                          type="button"
                          className="w-[96px] py-3 text-[#252A34B2] bg-[#F1F1F1] border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center hover:bg-[#00CED2] hover:text-white"
                          onClick={() => handleAmountClick("500000")} // Set the amount to N500,000
                        >
                          ₦500,000
                        </button>
                        <div class="relative">
                          <input
                            id="inputField"
                            class="text-[#252A3466] font-inter font-semibold text-[16px] leading-[28px] w-[174px] h-[50px] bg-[#EDF7F5] rounded-lg border border-[#787B824D] pl-7"
                            placeholder="| Other Amount"
                            type="number"
                            value={customAmount}
                            onChange={(e) => {
                              setSelectedAmount(e.target.value);
                              setCustomAmount(e.target.value);
                            }}
                          />
                          <span class="absolute left-2 top-1/2 transform -translate-y-1/2 font-inter font-semibold text-base leading-7 text-[#252A34]">
                            ₦
                          </span>
                        </div>
                      </div>

                      <p className="font-inter font-semibold text-[16px] leading-[28px] text-center text-[#252A34] mt-4">
                        Total Amount: ₦{formatNumberAsCurrency(selectedAmount)}
                      </p>

                      <div className="w-full lg:w-[600px] h-0 border-t-2 border-gray-300 rounded-full mb-[500px]"></div>

                      <div className="w-full flex flex-col gap-2">
                        <input
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          className="w-full lg:w-[423px] bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className="w-full lg:w-[423px] bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        />
                      </div>

                      <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={handleEmailChange}
                        className={`bg-[#EDF7F5] px-4 py-3 border ${
                          emailError ? "border-red-500" : "border-gray-300"
                        } rounded-lg focus:outline-none focus:ring-2 ${
                          emailError
                            ? "focus:ring-red-500"
                            : "focus:ring-[#00CED2]"
                        }`}
                      />
                      {emailError && (
                        <p className="text-red-500 text-sm mt-1">
                          {emailError}
                        </p>
                      )}

                      <div className="flex items-center space-x-2 mt-4">
                        <input
                          type="checkbox"
                          id="anonymous"
                          checked={isAnonymous} // assuming `isAnonymous` is a state variable in React
                          onChange={() => setIsAnonymous(!isAnonymous)} // Toggle the anonymous state
                          className="w-4 h-4 text-[#00CED2] border-gray-300 rounded"
                        />
                        <label
                          htmlFor="anonymous"
                          className="text-[#252A34] font-medium text-sm"
                        >
                          Anonymous
                        </label>
                      </div>
                      {/* <div class="flex items-center space-x-2">
                        <div class="w-[21px] h-[21px] rounded-[2px] border border-[#787B82]"></div>
                        <p class="text-[#252A34] font-medium text-sm">
                          Anonymous
                        </p>
                      </div> */}

                      <div className="mt-8">
                        <h1 className="text-[#252A34] font-inter font-semibold text-base leading-7 mb-4">
                          Choose a Payment Method
                        </h1>

                        <div className="space-y-2">
                          {data && data.length > 0 ? (
                            data.map((payment, index) => (
                              <div
                                key={index}
                                onClick={() =>
                                  handlePaymentMethodChange(payment.name)
                                }
                                className={`flex gap-6 items-center justify-between cursor-pointer w-full lg:w-[424px] p-4 border rounded-lg ${
                                  paymentMethod === payment.name
                                    ? " text-white"
                                    : " text-[#252A34]"
                                } hover:bg-[#00CED2]/10 bg-[#EDF7F5] hover:text-white`}
                              >
                                <div className="flex flex-row items-center gap-4">
                                  <img
                                    src={payment.logo_url}
                                    alt={payment.name}
                                    className="w-[36px] h-[32px]"
                                  />
                                  <span className="text-[#252A34] font-bold">
                                    {toTitleCase(payment.name)}
                                  </span>
                                </div>

                                <div
                                  className={`w-[20px] h-[20px] rounded-full border-2 ${
                                    paymentMethod === payment.name
                                      ? "bg-white border-[#00CED2]"
                                      : "bg-transparent border-[#252A34]"
                                  } flex items-center justify-center`}
                                >
                                  {paymentMethod === payment.name && (
                                    <div className="w-[12px] h-[12px] bg-[#00CED2] rounded-full"></div>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>No payment methods available</p>
                          )}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="w-[165px] py-3 bg-[#00CED2] text-white font-semibold text-lg rounded-lg mt-4 hover:bg-[#019d98] focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          <span className="flex items-center justify-center gap-x-1">
                            Pay ₦{formatNumberAsCurrency(selectedAmount)}
                          </span>
                        )}
                      </button>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Right Column: Image and Text */}
          <div className="flex flex-col justify-center items-start space-y-4 w-full lg:w-[443px] lg:px-4 text-center lg:text-left">
            <div className="relative">
              <img
                src={Img1}
                alt="hand"
                className="w-full lg:w-[443px] rounded-tl-[20px] rounded-br-[20px]"
              />
            </div>
            <div className="max-w-[443px] text-center lg:text-left space-y-4">
              <h1 className="font-normal text-[30px] leading-[38px] text-[#2D2D2D]">
                Empowering Communities | <br />
                Transforming Lives
              </h1>
              <p className="text-[#2D2D2D] font-normal text-lg leading-7 text-justify font-inter pr-4">
                At BOAF, we believe that every woman, child, and community
                leader has the potential to create a brighter future. Our
                mission is to empower women and nurture community leaders
                through impactful initiatives that address critical social
                issues, improve living conditions, and foster sustainable
                development.
              </p>
            </div>
          </div>
        </div>
      </div>

      <TransactionModal
        details={referenceDetails}
        transactionModal={showThankYouModal}
        setTransactionModal={setShowThankYouModal}
      />
    </div>
  );
};

export default Donate;
