import React, { useState } from "react";
import img from "../../assets/images/1c57d8f22d 2.png";
import mobileimg from "../../assets/images/1c57d8f22d 1.png";
import Helping from "../../assets/icons/helpHand.png";
import { useNavigate } from "react-router-dom";

const Involve = () => {
  const [isDonor, setIsDonor] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="bg-[#0F45C3] max-h-min md:h-[623px] lg:h-[673px] gap-6 md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full py-16 md:py-10">
      <div className="flex md:block  justify-end sm:justify-between">
        {/* Image Section */}
        <div className="">
          <img
            src={mobileimg}
            alt="hand"
            className=" -left-30 block md:hidden w-[15rem]  "
          />
          <img
            src={img}
            alt="hand"
            className=" absolute hidden h-[24rem] mt-5 lg:h-auto md:block left-[-220px]"
          />
        </div>

        {/* Text Section */}
        <div className="flex flex-col md:absolute md:left-[170px] lg:left-[370px] lg:pt-10 md:pt-[50px] px-6 space-y-3 md:space-y-5">
          <h1 className="text-[#00CED2] font-inter font-semibold text-[28px] lg:text-[40px] tracking-[-0.03em] ">
            Get Involved
          </h1>
          <div className="lg:hidden">
            <p className="font-inter  w-[12.5rem] font-medium italic text-[14px] md:text-[16px] text-[#FFFFFF] pb-2">
              Your support would make a difference
            </p>
            <div className="w-[10rem] lg:w-[332.01px] h-1 rounded-full bg-[#00CED2]"></div>
          </div>

          <p className="text-[#F4F4F4] font-light  lg:font-normal text-[14px] lg:text-[18px] w-[12rem] md:w-[13.5rem] lg:w-[358px]">
            At BOAF, we believe that every woman, child, and community leader
            has the potential to create a brighter future.
          </p>
          <div className="hidden lg:block w-[10rem] lg:w-[332.01px] h-1 rounded-full bg-[#00CED2]"></div>
          <p className="font-inter hidden lg:block font-medium italic text-[14px] md:text-[16px] leading-[24px] text-[#FFFFFF]">
            Your support would make a difference
          </p>
          <div className="bg-white space-y-2 w-full md:w-[13.5rem] h-auto md:h-[140.87px] p-4 rounded-md">
            <p className="text-[#2D2D2D] font-inter font-normal text-[14px] leading-[24px]">
              Join us to
            </p>
            <p className="text-[#000000] font-medium text-[16px] leading-[21px]">
              Impact 1,000 lives in 2025
            </p>
            <div className="px-5 w-full md:w-[154.54px] h-[30.09px] bg-[#175CD3] flex flex-row gap-4 justify-between  items-center rounded-full">
              <div className="w-[8.89px] h-[8.89px] bg-[#FFF8F2] rounded-full"></div>
              <div className="w-[48.55px] h-[7.52px] bg-[#FFF8F2] rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Interactive Section */}
      <div className="w-full flex flex-col lg:ml-[270px] xl:ml-[320px] pt-10 md:pt-[50px] px-6 space-y-8 md:space-y-10">
        {/* Button Section */}
        <div className="flex justify-center items-center bg-[#FFFFFF]/20 rounded-lg border border-[#00CED2]/20 p-2 w-full">
          <button
            onClick={() => setIsDonor(true)} // Set to Donor
            className={`text-[#FFFFFF] w-full  md:w-[183px]  rounded-[8px]  p-2 font-semibold text-xs lg:text-[16px]  text-center ${
              isDonor ? "border" : ""
            }`}
          >
            Become a Donor
          </button>
          <button
            onClick={() => setIsDonor(false)} // Set to Partner
            className={`text-[#FFFFFF] w-full  md:w-[183px]  rounded-[8px]  p-2 font-semibold text-xs lg:text-[16px]  text-center ${
              !isDonor ? "border" : ""
            }`}
          >
            Become a Partner
          </button>
        </div>

        {/* Conditional Content */}
        {isDonor ? (
          // Content for Become a Donor
          <div className="w-full lg:w-[520px] space-y-4">
            <p className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
              By donating you help to change lives. Your generosity provides
              safe homes, meals for hungry families, and skills that empower
              women to thrive. Whether it is a one-time gift or monthly support,
              every donation brings hope and transforms futures.
            </p>
            <h2 className="text-[#FFFFFF] font-inter font-bold text-[16px] md:text-[18px] leading-[24px]">
              Ways to Donate:
            </h2>
            <ul>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2] font-bold">
                  One-Time Donation:
                </span>{" "}
                Make an impact today with a single gift.
              </li>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2] font-bold">
                  Monthly Donation:
                </span>{" "}
                Join our community of monthly supporters for lasting change.
              </li>
            </ul>
            <div className="flex items-center gap-x-5">
              <button
                onClick={() => navigate("/donate")}
                className="inline-flex items-center justify-center w-full md:w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#0F45C3] font-inter font-bold text-sm leading-[30px] text-center "
              >
                Donate Now |
                <img
                  src={Helping}
                  alt="Helping Hand"
                  className="w-5 h-5 mr-2"
                />
              </button>
              <div className="w-[7rem] h-1 rounded-full bg-[#fff]"></div>
            </div>
          </div>
        ) : (
          // Content for Become a Partner
          <div className="w-full lg:w-[520px] space-y-4">
            <p className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
              If you’re passionate about our mission and ready to take it a step
              further, consider becoming a partner.
              <br /> As a partner, you not only support BOAF through donations,
              but you also help spread the word about our work. Partnering with
              us allows you to engage your network and encourage others to join
              in our mission, earning rewards for your contributions.
            </p>
            <h2 className="text-[#FFFFFF] font-inter font-bold text-[16px] md:text-[18px] leading-[24px]">
              As a Partner, You Will:
            </h2>
            <ul>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2] font-bold">Donation:</span>{" "}
                Contribute any amount to support our initiatives.
              </li>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2] font-bold">Promote:</span> Share
                our mission with your friends, family, and social networks.
              </li>
              <li className="text-[#FFFFFF] font-normal text-[14px] md:text-[16px] leading-[24px]">
                <span className="text-[#00CED2] font-bold">Earn Rewards:</span>{" "}
                For every three friends you invite to donate, you'll receive a
                financial reward of ₦12,500 or more depending on project
                supported.
                <br />
                Plus, you’ll be instrumental in building a culture of giving and
                community support.
              </li>
            </ul>
            <div className="flex items-center gap-x-5">
              <button
                onClick={() => navigate("/donate")}
                className="inline-flex items-center justify-center w-full md:w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#0F45C3] font-inter font-bold text-sm leading-[30px] text-center "
              >
                Donate Now
                <img
                  src={Helping}
                  alt="Helping Hand"
                  className="w-5 h-5 mr-2"
                />
              </button>
              <div className="w-[7rem] h-1 rounded-full bg-[#fff]"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Involve;
