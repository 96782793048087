import React, { useState } from "react";
// import HeasderIcon from "../../assets/icons/headerIcon.png";
import Helping from "../../assets/icons/helpHand.png";
import { useLocation, Link, NavLink } from "react-router-dom";
import TopLogo from "../../assets/images/topLogo.png";
import { motion } from "framer-motion";
const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="z-[100] sticky top-0">
      <header className="bg-white shadow-md">
        <div className="mx-auto hidden lg:flex  items-center justify-between py-5  px-24  ">
          {/* Logo Section */}
          <Link to="/">
            <img src={TopLogo} alt="logo" className="w-[202px] h-[49px]" />
          </Link>

          {/* Navigation Section */}
          <motion.nav
            initial={{ x: -300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            aria-label="Global"
          >
            <div className="flex gap-6 items-center">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#212121] font-inter text-[18px] font-bold leading-[30px] text-center"
                    : "text-gray-500 transition hover:text-gray-500/75 font-inter text-[18px] font-medium leading-[30px] text-center"
                }
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#212121] font-inter text-[18px] font-bold leading-[30px] text-center"
                    : "text-gray-500 transition hover:text-gray-500/75 font-inter text-[18px] font-medium leading-[30px] text-center"
                }
              >
                About Us
              </NavLink>

              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#212121] font-inter text-[18px] font-bold leading-[30px] text-center"
                    : "text-gray-500 transition hover:text-gray-500/75 font-inter text-[18px] font-medium leading-[30px] text-center"
                }
              >
                Contact Us
              </NavLink>

              <NavLink
                to="/login"
                className={({ isActive }) =>
                  isActive
                    ? "text-[#212121] font-inter text-[18px] font-bold leading-[30px] text-center"
                    : "text-gray-500 transition hover:text-gray-500/75 font-inter text-[18px] font-medium leading-[30px] text-center"
                }
              >
                Login
              </NavLink>
            </div>
          </motion.nav>

          {/* Donate Now Button */}
          <Link to="/donate">
            <button className="inline-flex items-center justify-center  w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#212121] font-inter font-bold text-sm leading-[30px] text-center">
              Donate Now |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </button>
          </Link>
        </div>

        <div className="lg:hidden item-center  flex justify-between px-3 py-5 bg-[#fff] ">
          <Link to="/">
            <img src={TopLogo} alt="logo" className="w-[200px] h-[47px]" />
          </Link>

          <div className="flex lg:hidden">
            {isOpen ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(false);
                }}
                className="text-[#576B74]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#576B74"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(true);
                }}
                className="text-[#576B74]  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                aria-label="toggle menu"
              >
                <svg
                  width="30"
                  height="26"
                  viewBox="0 0 30 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="6" rx="3" fill="#576B74" />
                  <rect y="10" width="30" height="6" rx="3" fill="#576B74" />
                  <rect y="20" width="15" height="6" rx="3" fill="#576B74" />
                </svg>
              </button>
            )}
          </div>
        </div>

        <div
          className={`${
            isOpen
              ? "translate-x-0 opacity-100 "
              : "opacity-0 -translate-x-full"
          } absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-[#fff] space-y-3`}
        >
          <div className="flex flex-col px-2 -mx-4 space-y-2">
            <Link
              to="/#"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/about"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
              onClick={() => setIsOpen(false)}
            >
              About Us
            </Link>

            <Link
              to="/contact"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
              onClick={() => setIsOpen(false)}
            >
              Contact Us
            </Link>

            <Link
              to="/login"
              className="px-2.5 py-2 text-[#576B74] font-bold transition-colors duration-300 transform rounded-lg  hover:bg-gray-100  md:mx-2"
              onClick={() => setIsOpen(false)}
            >
              Login
            </Link>

            <Link
              to="/donate"
              className="inline-flex items-center justify-center  w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#00CED2] text-[#212121] font-inter font-bold text-sm leading-[30px] text-center"
              onClick={() => setIsOpen(false)}
            >
              Donate Now |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
