import React from "react";
import house from "../../assets/images/house.jpg";
import twokids from "../../assets/images/two_kids.png";
import twowomen from "../../assets/images/two_women.png";
import Helping from "../../assets/icons/helpHand.png";
import { useNavigate } from "react-router-dom";

const Initiatives = () => {
  const navigate = useNavigate();
  return (
    <div className="py-16 px-2 lg:px-6 bg-gray-50 w-full">
      <div className="max-w-screen mx-auto flex flex-col items-center">
        <h1 className="font-normal text-[40px] leading-[38px] tracking-[-0.03em] font-inter text-[#212121] mb-4">
          Our Initiatives
        </h1>
        <p className="  text-center text-[#2D2D2D] font-normal text-sm sm:text-[18px] leading-[24px]  font-inter mb-12 lg:w-[552px]">
          At BOAF, we believe that every woman, child, and community leader has
          the potential to create a brighter future.
        </p>

        <div className="md:overflow-x-auto md:rounded-lg">
          {/* Initiatives Grid */}
          <div className="grid md:flex md:w-screen lg:w-full p-2 sm:p-4 lg:grid grid-cols-1  md:grid-cols-3 gap-8 ">
            {/* Initiative 1: House */}
            <div className="md:w-[400px] sm:h-[600px] flex flex-col justify-evenly  gap-4  bg-white p-4 rounded-lg shadow-lg transition transform hover:scale-105 hover:shadow-xl">
              <div className="p-2 lg:w-[371px] h-[250px] bg-[#E4E4E4] rounded-[8px] flex flex-col items-center">
                <div className="w-[157px] h-[3px] bg-[#00CED2] rounded-full absolute top-2  "></div>
                <img
                  src={house}
                  alt="Housing Initiative"
                  className="w-full h-[232px] object-cover rounded-t-lg"
                />
              </div>

              <h2 className="font-bold text-[24px] leading-[30px] text-[#292C30] mb-2 ">
                Container Housing Projects{" "}
              </h2>
              <p className="font-normal text-[16px] leading-[24px] font-inter text-[#000000] text-left">
                We provide homes for homeless families with innovative,
                sustainable housing solutions. Our eco-friendly container homes
                are not only affordable but also built to create safe, secure
                spaces where families can rebuild and thrive.
              </p>
              <div className="sm:w-[331.01px] border-t-[1px] border-gray-300 "></div>
              <div className="flex space-x-4 items-center justify-center sm:w-[331.01px]">
                <button
                  onClick={() => navigate("/donate")}
                  className="bg-[#00CED2] w-[231px] h-[44px] rounded-[100px] border-[1px] p-2 gap-[1px] text-[#0F45C3] font-normal text-[16px] leading-[30px] text-center font-inter flex items-center justify-center"
                >
                  Partner with us
                  <img
                    src={Helping}
                    alt="Helping Hand"
                    className="w-5 h-5 ml-2"
                  />
                </button>
                <div className="w-[86px] h-[4px] bg-gray-300"></div>
              </div>
            </div>

            {/* Initiative 2: Two Kids */}
            <div className="md:w-[400px] sm:h-[600px] flex flex-col justify-evenly  gap-4  bg-white p-4 rounded-lg shadow-lg transition transform hover:scale-105 hover:shadow-xl">
              <div className="p-2 lg:w-[371px] h-[250px] bg-[#E4E4E4] rounded-[8px] flex flex-col items-center">
                <div className="w-[157px] h-[3px] bg-[#00CED2] rounded-full absolute top-2  "></div>
                <img
                  src={twokids}
                  alt="Children Empowerment"
                  className="w-full h-[232px] object-cover rounded-t-lg"
                />
              </div>
              <h2 className="font-bold text-[24px] leading-[30px] text-[#292C30] mb-2 ">
                Feeding Programs
              </h2>
              <p className="font-normal text-[16px] leading-[24px] font-inter text-[#000000] text-left">
                With a commitment to combat hunger and malnutrition, we operate
                community feeding programs that deliver nutritious meals to
                families facing hardship. Our goal is to ensure that no child
                goes to bed hungry.
              </p>
              <div className="sm:w-[331.01px] border-t-[1px] border-gray-300 "></div>
              <div className="flex space-x-4 items-center justify-center sm:w-[331.01px]">
                <button
                  onClick={() => navigate("/donate")}
                  className="bg-[#00CED2] w-[231px] h-[44px] rounded-[100px] border-[1px] p-2 gap-[1px] text-[#0F45C3] font-normal text-[16px] leading-[30px] text-center font-inter flex items-center justify-center"
                >
                  Partner with us
                  <img
                    src={Helping}
                    alt="Helping Hand"
                    className="w-5 h-5 ml-2"
                  />
                </button>
                <div className="w-[86px] h-[4px] bg-gray-300"></div>
              </div>
            </div>

            {/* Initiative 3: Two Women */}
            <div className="md:w-[400px] sm:h-[600px] flex flex-col justify-evenly  gap-4  bg-white p-4 rounded-lg shadow-lg transition transform hover:scale-105 hover:shadow-xl">
              <div className="p-2 lg:w-[371px] h-[250px] bg-[#E4E4E4] rounded-[8px] flex flex-col items-center">
                <div className="w-[157px] h-[3px] bg-[#00CED2] rounded-full absolute top-2  "></div>
                <img
                  src={twowomen}
                  alt="Women Empowerment"
                  className="w-[351px] h-[232px] object-cover rounded-t-lg lg:mb-6"
                />
              </div>
              <h2 className="font-bold text-[24px] leading-[30px] text-[#292C30] mb- mt-">
                Community Empowerment
              </h2>
              <p className="font-normal text-[16px] leading-[24px] font-inter text-[#000000] text-left">
                We empower women to rewrite their stories and ignite change
                within their communities. We organise workshops on financial
                literacy and nurture the strength and potential of women,
                turning challenges into opportunities for lasting
                transformation.
              </p>
              <div className="sm:w-[331.01px] border-t-[1px] border-gray-300 "></div>
              <div className="flex space-x-4 items-center justify-center sm:w-[331.01px]">
                <button
                  onClick={() => navigate("/donate")}
                  className="bg-[#00CED2] w-[231px] h-[44px] rounded-[100px] border-[1px] p-2 gap-[1px] text-[#0F45C3] font-normal text-[16px] leading-[30px] text-center font-inter flex items-center justify-center"
                >
                  Partner with us
                  <img
                    src={Helping}
                    alt="Helping Hand"
                    className="w-5 h-5 ml-2"
                  />
                </button>
                <div className="w-[86px] h-[4px] bg-gray-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Initiatives;
