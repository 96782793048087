import React, { useEffect } from "react";
import Initiatives from "../components/initiatives/initiatives";
import SEO from "../components/SEO";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <SEO
        title="About"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      <Initiatives />
    </div>
  );
};

export default About;
