import React, { useEffect, useState } from "react";
import Img from "../assets/images/Group 3.png";
import Img1 from "../assets/images/address.png";
import SEO from "../components/SEO";
import { useParams } from "react-router-dom";
import { useGetReferralCodeInfoQuery } from "../Slices/userServiceSlice";
import { useApi } from "../context/ApiContext";
import Spinner from "../components/preloader/Spinner";
import { useGetPaymentMethodsQuery } from "../Slices/paymentServiceSlice";
import { toTitleCase } from "../Slices/utilSlice";
import { motion } from "framer-motion";

const Referal = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const params = useParams();
  const { code } = params;
  const { getData, postData, loading, error } = useApi();
  const [referralCodeDetails, setReferralCodeDetails] = useState([]);
  const [methods, setMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [display, setDisplay] = useState(false);
  const [referralCode, setReferralCode] = useState(null);

  const [clickedIndex, setClickedIndex] = useState(null);
  const {
    data,
    isLoading: isloading,
    isError,
  } = useGetReferralCodeInfoQuery(code);
  const { data: paymethods } = useGetPaymentMethodsQuery();
  useEffect(() => {
    if (data) {
      setReferralCodeDetails(data.data);
    }
  }, [data]);
  useEffect(() => {
    if (paymethods) {
      setMethods(paymethods.data);
    }
  }, [paymethods]);

  const [referrerCode, setReferrerCode] = useState("");

  const fetchData = async (amount) => {
    try {
      // Make GET request with Amount as a query parameter
      const result = await getData(
        `/userService/referrals/getReferralCodesForRegistration?donationAmount=${amount}`
      );
      setReferralCode(result.data);
      setDisplay(true);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // email validation regex

    setEmail(value); // Update email value

    // Check if the email is valid or empty
    if (value === "" || emailRegex.test(value)) {
      setEmailError(""); // Clear error
    } else {
      setEmailError("Please enter a valid email address."); // Show error
    }
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        amount: referralCodeDetails.amount_for_donation.toString(), // Selected or custom amount
        methodInUse: paymentMethod,
        donationType: "partnership",
        anonymous: isAnonymous,
        metaInformation: {
          email,
          donorFirstName: firstName,
          donorLastName: lastName,
          referrerCode: referrerCode || referralCodeDetails.referral_code,
        },
      };

      // Make the POST request via context
      const response = await postData(
        "/paymentService/payment/initiatePayment",
        payload
      );

      setIsLoading(false);
      window.location.href = `${response.data.authorizationUrl}`;

      // Reset form fields
      setIsAnonymous(false);
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (err) {
      console.error("Error submitting donation:", err);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <SEO
        title="Referral"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      <div className="relative">
        <img
          src={Img}
          alt="Tomae"
          className="top-[50%] w-full h-[242px] object-cover z-0"
        />
        <h1 className="absolute top-[150px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[#00CED2] w-[80%] md:w-full h-[58px] font-inter font-semibold text-[40px] sm:text-[60px] leading-[58px] tracking-[-0.03em] text-center z-10">
          Partnership Via Referral
        </h1>
      </div>

      {/* Image and Text Section */}
      <div className="flex justify-center items-center my-[50px]">
        {/* Main Section: Two-Column Grid */}
        <div className="flex flex-col lg:flex-row justify-center gap-10 w-full px-4">
          {/* Left Column: Empowering Communities */}
          <div className="p-4 lg:p-[70px] w-full lg:w-[755px] bg-[#E4E4E4] border border-[#E4E4E4] shadow-[12px_12px_23.1px_0px_rgba(0,0,0,0.1)] rounded-tr-[20px]">
            <h1 className="font-inter font-semibold text-[20px] leading-[26px] text-[#252A34] mb-6">
              Become part of this great mission with your support. Help us put a
              smile on their faces.
            </h1>

            <form onSubmit={handlePartnerSubmit} className="space-y-3">
              <div className="w-full space-y-4">
                {isloading ? (
                  <div className="pt-10">
                    <Spinner />
                  </div>
                ) : isError ? (
                  <div className="pt-5">Error</div>
                ) : (
                  <div className="space-y-2">
                    <h1 className="text-xl font-bold">
                      Donation Amount: ₦
                      {referralCodeDetails &&
                        referralCodeDetails.amount_for_donation &&
                        referralCodeDetails.amount_for_donation.toLocaleString()}
                    </h1>
                    <h1 className="text-xl font-bold flex items-center gap-x-2">
                      Referrer Code:
                      {referralCodeDetails.status === "active" ? (
                        <span>{referralCodeDetails.referral_code}</span>
                      ) : (
                        <div className="flex items-center gap-x-2 text-sm bg-red-100 rounded-md p-2">
                          Expired!{" "}
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              fetchData(
                                referralCodeDetails.amount_for_donation
                              );
                            }}
                            className="bg-green-700 text-white p-1 rounded-md text-xs animate-pulse"
                          >
                            Get an Active Code
                          </button>
                        </div>
                      )}
                    </h1>
                  </div>
                )}
              </div>
              {(referralCodeDetails.status === "active" || display) && (
                <>
                  {display && (
                    <div className="space-y-2">
                      {referralCode && referralCode.length > 0 ? (
                        referralCode.map((code, index) => (
                          <button
                            key={index}
                            className={`w-full lg:w-[423px] py-3 border border-[#787B8280] 
                                text-[#212121] font-bold rounded-lg font-inter text-base 
                                  leading-7 text-center 
                                  ${
                                    clickedIndex === index
                                      ? "bg-[#00CED2] text-white"
                                      : "hover:bg-[#00CED2] hover:text-white   bg-[#FFFFFF]"
                                  }`}
                            onClick={(e) => {
                              e.preventDefault();
                              setClickedIndex(index);
                              setReferrerCode(code.referral_code);
                            }} // Handle the click for each amount
                          >
                            {code.referral_code}
                          </button>
                        ))
                      ) : (
                        <p>No Referral code available</p>
                      )}
                    </div>
                  )}

                  <div className="w-full lg:w-[423px] h-0 border-t-2 border-gray-300 rounded-full "></div>

                  <div className="w-full lg:w-[423px] flex flex-col gap-2">
                    <motion.div
                      initial={{ y: -30, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.4 }}
                      className="flex gap-x-2 items-center bg-[#FEF6E7] p-1 rounded-lg "
                    >
                      <svg
                        width="60"
                        height="61"
                        viewBox="0 0 60 61"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.27042 40.9442L24.0483 11.5114C26.5581 6.82954 33.4419 6.82953 35.9517 11.5114L51.7296 40.9441C54.0614 45.294 50.8189 50.5 45.7779 50.5H14.2221C9.18106 50.5 5.93861 45.294 8.27042 40.9442ZM30 15.5C31.3807 15.5 32.5 16.6193 32.5 18V35.5C32.5 36.8807 31.3807 38 30 38C28.6193 38 27.5 36.8807 27.5 35.5V18C27.5 16.6193 28.6193 15.5 30 15.5ZM26.875 42.375C26.875 44.1009 28.2741 45.5 30 45.5C31.7259 45.5 33.125 44.1009 33.125 42.375C33.125 40.6491 31.7259 39.25 30 39.25C28.2741 39.25 26.875 40.6491 26.875 42.375Z"
                          fill="#F5B546"
                        />
                      </svg>
                      <p className="text-xs sm:text-sm">
                        Enter your Name as registered with your bank
                      </p>
                    </motion.div>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      className=" bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      className="bg-[#EDF7F5] px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
                    />
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={handleEmailChange}
                      className={`bg-[#EDF7F5] px-4 py-3 border ${
                        emailError ? "border-red-500" : "border-gray-300"
                      } rounded-lg focus:outline-none focus:ring-2 ${
                        emailError
                          ? "focus:ring-red-500"
                          : "focus:ring-[#00CED2]"
                      }`}
                    />
                    {emailError && (
                      <p className="text-red-500 text-sm mt-1">{emailError}</p>
                    )}
                  </div>

                  {/* Choose a Payment Method  */}
                  <div className="mt-8">
                    <h1 className="text-[#252A34] font-inter font-semibold text-base leading-7 mb-4">
                      Choose a Payment Method
                    </h1>

                    <div className="space-y-2">
                      {methods && methods.length > 0 ? (
                        methods.map((payment, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handlePaymentMethodChange(payment.name)
                            }
                            className={`flex gap-6 items-center justify-between cursor-pointer w-full lg:w-[424px] p-4 border rounded-lg ${
                              paymentMethod === payment.name
                                ? " text-white"
                                : " text-[#252A34]"
                            } hover:bg-[#00CED2]/10 bg-[#EDF7F5] hover:text-white`}
                          >
                            <div className="flex flex-row items-center gap-4">
                              <img
                                src={payment.logo_url}
                                alt={payment.name}
                                className="w-[36px] h-[32px]"
                              />
                              <span className="text-[#252A34] font-bold">
                                {toTitleCase(payment.name)}
                              </span>
                            </div>

                            <div
                              className={`w-[20px] h-[20px] rounded-full border-2 ${
                                paymentMethod === payment.name
                                  ? "bg-white border-[#00CED2]"
                                  : "bg-transparent border-[#252A34]"
                              } flex items-center justify-center`}
                            >
                              {paymentMethod === payment.name && (
                                <div className="w-[12px] h-[12px] bg-[#00CED2] rounded-full"></div>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No payment methods available</p>
                      )}
                    </div>
                  </div>

                  <button
                    className="mt-4 w-full lg:w-[423px] h-[50px] bg-[#00CED2] text-[#fff] rounded-[15px] font-semibold text-lg"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <span className="flex items-center justify-center gap-x-1">
                        Proceed as a Partner
                      </span>
                    )}
                  </button>
                </>
              )}
            </form>
          </div>

          {/* Right Column: Image and Text */}
          <div className="flex flex-col justify-center items-start space-y-4 w-full lg:w-[443px] lg:px-4 text-center lg:text-left">
            <div className="relative">
              <img
                src={Img1}
                alt="hand"
                className="w-full lg:w-[443px] rounded-tl-[20px] rounded-br-[20px]"
              />
            </div>
            <div className="max-w-[443px] text-center lg:text-left space-y-4">
              <h1 className="font-normal text-[30px] leading-[38px] text-[#2D2D2D]">
                Empowering Communities | <br />
                Transforming Lives
              </h1>
              <p className="text-[#2D2D2D] font-normal text-lg leading-7 text-justify font-inter pr-4">
                At BOAF, we believe that every woman, child, and community
                leader has the potential to create a brighter future. Our
                mission is to empower women and nurture community leaders
                through impactful initiatives that address critical social
                issues, improve living conditions, and foster sustainable
                development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referal;