import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Donate from "./pages/donate";
import Partner from "./pages/partner";
import Dashboard from "./pages/dashboard";
import { ApiProvider } from "./context/ApiContext";
import About from "./pages/about";
import MainLayout from "./components/layouts/MainLayout";
import PartnersDashboardLayout from "./components/layouts/PartnersDashboardLayout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthRoute from "./components/AuthRoute";
import Account from "./pages/Dashboard/Account";
import Index from "./pages/Dashboard/Index";
import Story from "./pages/story";
import Volunteer from "./pages/volunteer";
import Referal from "./pages/referal";
import Login from "./pages/Dashboard/Login";

export default function App() {
  return (
    <ApiProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/partner" element={<Partner />} />
            <Route path="/volunteer" element={<Volunteer />} />
            <Route path="/referral/:code" element={<Referal />} />
            <Route path="/tell_us_your_story" element={<Story />} />
          </Route>
          {/* Partners dashboard Routing */}
          <Route path="" element={<AuthRoute />}>
            <Route path="dashboard" element={<PartnersDashboardLayout />}>
              <Route index element={<Navigate to="index" replace />} />
              <Route path="index" element={<Index />} />
              <Route path="account" element={<Account />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ApiProvider>
  );
}
