import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../preloader/Spinner";
import { toast } from "react-toastify";
import { useApi } from "../../context/ApiContext";

function ProfileSetting() {
  const { userInfo } = useSelector((state) => state.auth);
    const { postData } = useApi();
  const [phoneNumber, setPhoneNumber] = useState(userInfo.data.phone_number);
   const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        email: userInfo.data.email,
        firstName: userInfo.data.first_name,
        lastName: userInfo.data.last_name,
        phoneNumber
      };

     

      console.log(payload);

      // Make the POST request via context
      const response = await postData(
        "/userService/user/dashboard/updateUserProfile",
        payload
      );

      console.log("Update submitted successfully:", response);
      setIsLoading(false);
      toast.success(response?.data?.message || response.message);
    } catch (err) {
      toast.error(err?.data?.message || err.message);
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="space-y-4 px-4 pb-20  transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:max-w-md sm:px-6 md:px-8  ">
      <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto mt-1" />
      <div>
        <label htmlFor="" className="text-left py-3 font-semibold">
          Full Name
        </label>
        <h1 className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]">
          {userInfo.data.first_name + " " + userInfo.data.last_name}
        </h1>
      </div>
      <div>
        <label htmlFor="" className="text-left py-3 font-semibold">
          Email
        </label>
        <h1 className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]">
          {userInfo.data.email}
        </h1>
      </div>
      <div>
        <label htmlFor="" className="text-left py-2 font-semibold">
          Phone Number
        </label>
        <input
          type="text"
          placeholder="234"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
        />
      </div>

      <button
        className="w-full py-2 bg-[#00CED2] text-[#fff] rounded-[15px] font-semibold"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <span className="flex items-center justify-center gap-x-1">
            Update Profile
          </span>
        )}
      </button>

      <hr className="w-full" />
    </form>
  );
}

export default ProfileSetting;
