import React, { useState } from "react";
import bagIm from "../../assets/images/testimage.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Helping from "../../assets/images/bluehand.png";
import testi from "../../assets/images/Group 3570.png";
import { Link } from "react-router-dom";

const Testimonial = () => {
  const testimonials = [
    {
      id: 1,
      name: "Folake Adebayo",
      message:
        "BOAF has transformed my life in unimaginable ways. The support and encouragement I’ve received are unparalleled.",
    },
    {
      id: 2,
      name: "Chidinma Daniels",
      message:
        "Joining BOAF was the best decision I’ve ever made. It’s inspiring to see how much impact we can create together.",
    },
    {
      id: 3,
      name: "Etim Akpan",
      message:
        "A truly life-changing experience. BOAF empowers people to achieve their dreams and build strong communities.",
    },
  ];

  const [current, setCurrent] = useState(0);

  const handleNext = () => {
    setCurrent((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrent((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  return (
    <div className="flex flex-col w-full justify-center items-center mt-7 space-y-12 px-4 sm:px-6 lg:px-12">
      {/* Background Section */}
      <div
        className="relative bg-cover bg-center h-[300px] lg:w-[1211px] sm:h-[400px] md:h-[500px] flex flex-col items-center justify-center text-white w-full rounded-lg overflow-hidden"
        style={{ backgroundImage: `url(${bagIm})` }}
      >
        {/* Testimonial Header */}
        <div className="space-y-2 sm:space-y-4 mb-4 px-4 text-center">
          <p className="text-[#2D2D2D] font-inter font-semibold text-[16px] sm:text-[18px] leading-[24px]">
            Success Stories
          </p>
          <h1 className="text-[#212121] font-bold text-[24px] sm:text-[39px] leading-[30px] sm:leading-[50px]">
            What People Say
          </h1>
        </div>

        {/* Testimonial Content */}
        <div className="w-full sm:w-[80%] max-w-3xl text-center space-y-4 p-4 sm:p-8 rounded-lg">
          <h3 className="text-[#212121] font-bold text-[16px] sm:text-[20px] leading-[24px] sm:leading-[28px]">
            {testimonials[current].name}
          </h3>
          <p className="font-inter font-semibold text-[14px] sm:text-[20px] leading-[20px] sm:leading-[28px] text-center text-[#212121]">
            {testimonials[current].message}
          </p>
        </div>

        {/* Navigation Buttons */}
        <button
          onClick={handlePrev}
          className="absolute left-4 sm:left-8 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 sm:p-3 rounded-full hover:bg-black/80 transition"
        >
          <FaChevronLeft size={20} />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-4 sm:right-8 top-1/2 transform -translate-y-1/2 bg-black/60 p-2 sm:p-3 rounded-full hover:bg-black/80 transition"
        >
          <FaChevronRight size={20} />
        </button>
      </div>

      {/* Bottom Section */}
      <div className="flex flex-col lg:flex-row items-center justify-center gap-12 lg:gap-20">
        {/* Image Section */}
        <div className="relative w-full max-w-[300px] sm:max-w-[600px]">
          <img src={testi} alt="Testimonial" className="w-full rounded-lg" />
        </div>

        {/* Text Section */}
        <div className="text-left lg:max-w-lg space-y-4">
          <p className="font-semibold text-lg leading-7 font-inter text-[#252A34]">
            Help put a Smile on their faces
          </p>
          <h1 className="font-medium text-2xl sm:text-4xl leading-8 sm:leading-12 text-[#252A34]">
            Together, we can create lasting change
          </h1>
          <p className="text-[#444444] font-normal text-sm sm:text-base leading-5 sm:leading-6 font-inter md:pr-10 lg:pr-0">
            Join us in bringing hope to thousands of women and children.
            Together, we can make a meaningful difference in their lives.
          </p>
          <Link to="/donate">
            <button className="mt-5 inline-flex items-center justify-center w-[196px] h-[44px] px-6 py-1.5 gap-2 rounded-full bg-[#0F45C3] text-[#00CED2] font-inter font-bold text-sm leading-[30px]">
              Donate Now |
              <img src={Helping} alt="Helping Hand" className="w-5 h-5 mr-2" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
