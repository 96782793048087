import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ShareLink() {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const handleCopy = () => {
    navigator.clipboard
      .writeText(apiBaseUrl + "referral/" + userInfo.data.referral_code)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  return (
    <div className="space-y-4 px-4 pb-20 text-center transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:max-w-md sm:px-6 md:px-8  ">
      <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto mt-1" />
      <div className="flex-shrink-0">
        <img
          className="h-28 w-28 lg:h-36 lg:w-36 rounded-lg mx-auto"
          src="../ShareAvatar.svg"
          alt=""
        />
      </div>

      <div className="mt-6 px-2 lg:px-6 text-xs py-2 flex items-center justify-center gap-x-2  bg-[#00CED2] rounded-md shadow-xl">
        {apiBaseUrl + "referral/" + userInfo.data.referral_code}
      </div>
      <span className="font-normal text-xs text-[#000] underline decoration-sky-400 underline-offset-4 decoration-from-font">
        Share referral link to Friends:
      </span>
      <div className="justify-center items-center lg:mb-0  gap-2 flex py-1">
        <button
          onClick={handleCopy}
          className="bg-[#8F8F8F] flex items-center text-sm text-white py-1 px-2 rounded-lg"
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-5"
          >
            <path
              d="M20.0934 2.16675H10.2396C8.1777 2.16675 6.49984 3.84461 6.49984 5.90652V6.50008H5.90628C3.84437 6.50008 2.1665 8.17795 2.1665 10.2399V20.0935C2.1665 22.1555 3.84437 23.8334 5.90628 23.8334H15.76C17.6542 23.8334 19.2073 22.4123 19.4502 20.5834H20.0933C22.1553 20.5834 23.8332 18.9055 23.8332 16.8436V5.90652C23.8332 3.84461 22.1553 2.16675 20.0934 2.16675ZM21.6665 16.8436C21.6665 17.7111 20.9608 18.4167 20.0934 18.4167H19.4998V10.2399C19.4998 8.17795 17.822 6.50008 15.7601 6.50008H8.6665V5.90652C8.6665 5.0391 9.37219 4.33341 10.2396 4.33341H20.0933C20.9608 4.33341 21.6665 5.0391 21.6665 5.90652V16.8436Z"
              fill="white"
            />
          </svg>
          {isCopied ? "Copied!" : "Copy"}
        </button>
        {/* Twitter */}
        <a
          href={`https://twitter.com/intent/tweet/?text=Partner with BOAF Community using my referral Link.&url=${
            apiBaseUrl + "referral%2F" + userInfo.data.referral_code
          }`}
          target="_blank"
          rel="noopener"
          className="bg-[#000] text-lightBlue-600  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
        >
          <svg
            className="w-8 h-5"
            fill="#4CC5D2"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
          </svg>
        </a>
        {/* whatsapp */}
        <a
          href={`https://api.whatsapp.com/send?text=Partner with BOAF Community using my referral Link.&url=${
            apiBaseUrl + "referral%2F" + userInfo.data.referral_code
          }`}
          target="_blank"
          rel="noopener"
          className="bg-[#000] text-lightBlue-600  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
        >
          <svg
            className="w-8 h-5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#4cc5d2"
              d="M12.001 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.005 22l1.352-4.968A9.953 9.953 0 0 1 2.001 12c0-5.523 4.477-10 10-10ZM8.593 7.3l-.2.008a.961.961 0 0 0-.372.1a1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.73 2.73 0 0 0 6.9 9.62c.002.49.13.967.33 1.413c.409.902 1.082 1.857 1.97 2.742c.214.213.424.427.65.626a9.448 9.448 0 0 0 3.84 2.046l.568.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231a4.83 4.83 0 0 0 .383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288c.083-.086.155-.187.21-.302c.078-.163.156-.474.188-.733c.024-.198.017-.306.014-.373c-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.402-.621a.497.497 0 0 0-.176-.041a.482.482 0 0 0-.378.127c-.005-.002-.072.055-.795.931a.35.35 0 0 1-.368.13a1.43 1.43 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.108a6.025 6.025 0 0 1-1.575-1.003c-.126-.11-.243-.23-.363-.346a6.297 6.297 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41c.11-.14.203-.276.263-.373c.118-.19.155-.385.093-.536c-.28-.684-.57-1.365-.868-2.041c-.059-.134-.234-.23-.393-.249c-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004l.201-.008Z"
            />
          </svg>
        </a>
        {/* LinkedIn */}
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${
            apiBaseUrl + "referral%2F" + userInfo.data.referral_code
          }`}
          target="_blank"
          rel="noopener"
          className="bg-[#000] text-lightBlue-600  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
        >
          <svg
            className="w-8 h-4"
            width="38"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.99134C0 1.41413 0.202708 0.937939 0.608108 0.56277C1.01351 0.187584 1.54055 0 2.18919 0C2.82626 0 3.34169 0.184693 3.73552 0.554112C4.14092 0.935065 4.34363 1.43145 4.34363 2.04329C4.34363 2.5974 4.14672 3.05915 3.7529 3.42857C3.3475 3.80952 2.81467 4 2.15444 4H2.13707C1.49999 4 0.984562 3.80952 0.590734 3.42857C0.196905 3.04762 0 2.56854 0 1.99134ZM0.225869 17.1429V5.57576H4.08301V17.1429H0.225869ZM6.22008 17.1429H10.0772V10.684C10.0772 10.2799 10.1236 9.96824 10.2162 9.74891C10.3784 9.35641 10.6245 9.02452 10.9546 8.75324C11.2847 8.48195 11.6988 8.34632 12.1969 8.34632C13.4942 8.34632 14.1429 9.21788 14.1429 10.961V17.1429H18V10.5108C18 8.8023 17.5946 7.50649 16.7838 6.62337C15.973 5.74026 14.9015 5.2987 13.5695 5.2987C12.0753 5.2987 10.9112 5.93939 10.0772 7.22078V7.25541H10.0598L10.0772 7.22078V5.57576H6.22008C6.24324 5.94516 6.25483 7.09378 6.25483 9.02164C6.25483 10.9495 6.24324 13.6565 6.22008 17.1429Z"
              fill="#4CC5D2"
            />
          </svg>
        </a>
        {/* Facebook */}
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${
            apiBaseUrl + "referral%2F" + userInfo.data.referral_code
          }`}
          target="_blank"
          rel="noopener"
          className="bg-[#000] text-lightBlue-600  font-normal h-8 w-8  py-1.5 items-center justify-center align-center rounded-full outline-none focus:outline-none  "
        >
          <svg
            className="w-8 h-5"
            width="38"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.37296 18V9.78936H9.26765L9.70104 6.5895H6.37288V4.54653C6.37288 3.6201 6.64305 2.98879 8.0385 2.98879L9.81819 2.98799V0.126072C9.51038 0.0871459 8.45386 0 7.22488 0C4.65886 0 2.90212 1.49118 2.90212 4.22972V6.5895H0V9.78936H2.90212V17.9999H6.37296V18Z"
              fill="#4CC5D2"
            />
          </svg>
        </a>
      </div>
      <button
        onClick={() => navigate("/contact")}
        className="px-6 py-2 bg-[#CCCCCC] font-semibold rounded-md shadow-xl"
      >
        Leave us a comment
      </button>
    </div>
  );
}

export default ShareLink;
