import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../Slices/authSlice";
import useIdleTimeout from "../useIdleTimeout";
import Logo from "../../assets/images/dblogo.svg";

function PartnersDashboardLayout() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  // Logout Session
  const dispatch = useDispatch();
  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };
  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
  };
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 100 });

  const stay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };
  // Function to handle actions on timeout (e.g., logout, redirect)
  const handleOnTimeout = () => {
    setOpenModal(false);
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div className="flex font-Inter">
      <div
        className={`sidebar w-[17rem] hidden lg:block overflow-hidden   shadow-lg bg-[#0F45C3] min-h-screen );
					`}
      >
        <div className="flex flex-col justify-between  pb-6">
          <div className="min-w-max px-5 ">
            <div className="px-2 py-8">
              <img src={Logo} alt="logo" />
            </div>
          </div>
          <ul className=" tracking-wide overflow-y-auto scrollbar-hide">
            <li className="min-w-max px-4 hover:border-l-2">
              <NavLink
                to="/dashboard/index"
                className={({ isActive }) =>
                  isActive
                    ? "flex w-full items-center px-3 py-4 text-base font-bold text-[#0F45C3] capitalize transition-colors duration-300 transform  bg-[#E4E4E4] border-l-2 space-x-2 rounded-full"
                    : "flex w-full items-center px-3 py-4 text-base font-normal text-[#E4E4E4] capitalize transition-colors duration-300 transform  hover:bg-[#E4E4E4] hover:text-[#0F45C3] space-x-2   rounded-full"
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6">
                    <path
                      d="M14.6198 1.93606C12.9917 1.02131 11.0083 1.02131 9.38018 1.93606L3.34356 5.32762C1.88548 6.14682 1.88548 8.25428 3.34356 9.07348L9.38018 12.465C11.0083 13.3798 12.9917 13.3798 14.6198 12.465L20.6564 9.07348C22.1145 8.25428 22.1145 6.14682 20.6564 5.32762L14.6198 1.93606Z"
                      fill="currentColor"
                    />
                    <path
                      d="M2.97517 15.3369C1.89805 16.2769 2.02084 18.0802 3.34356 18.8234L9.38018 22.2149C11.0083 23.1297 12.9917 23.1297 14.6198 22.2149L20.6564 18.8234C21.9792 18.0802 22.102 16.2769 21.0248 15.3369L15.3545 18.5227C13.2701 19.6938 10.7299 19.6938 8.64545 18.5227L2.97517 15.3369Z"
                      fill="currentColor"
                    />
                    <path
                      d="M2.81218 10.4954C1.91468 11.4673 2.0918 13.1201 3.34356 13.8234L9.38018 17.2149C11.0083 18.1297 12.9917 18.1297 14.6198 17.2149L20.6564 13.8234C21.9082 13.1201 22.0853 11.4673 21.1878 10.4954L15.3545 13.7727C13.2701 14.9438 10.7299 14.9438 8.64545 13.7727L2.81218 10.4954Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <span className="text-sm font-medium">Home</span>
              </NavLink>
            </li>
            <li className="min-w-max px-4 hover:border-l-2 ">
              <NavLink
                to="/dashboard/account"
                className={({ isActive }) =>
                  isActive
                    ? "flex w-full items-center px-3 py-4 text-base font-bold text-[#0F45C3] capitalize transition-colors duration-300 transform  bg-[#E4E4E4] border-l-2 space-x-2 rounded-full"
                    : "flex w-full items-center px-3 py-4 text-base font-normal text-[#E4E4E4] capitalize transition-colors duration-300 transform  hover:bg-[#E4E4E4] hover:text-[#0F45C3] space-x-2  rounded-full " 
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6">
                    <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>

                <span className=" text-sm font-normal">My Account</span>
              </NavLink>
            </li>


          </ul>
        </div>
      </div>
      <main
        className={`bg-[#F9FAFA] 
				 flex flex-grow flex-col  transition-all duration-150 ease-in   w-full lg:w-auto`}
      >
        <header className="lg:bg-white z-[100] bg-[#FFF]  sticky top-0">
          <nav
            className="mx-auto hidden lg:flex max-w-7xl items-center justify-between  py-4 px-6 lg:px-8  lg:gap-x-14 xl:gap-x-20"
            aria-label="Global"
          >
            <h2 className="text-xl text-black font-semibold">
              Welcome Back, {userInfo.data.first_name}
            </h2>
            <div className="flex gap-2 items-center">
              <div className="relative inline-block group">

                  <button
                    type="button"
                    className="flex items-center gap-x-2 text-sm font-bold leading-6  py-2  px-3 rounded-xl"
                    aria-expanded="false"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-lg"
                          src="../Avatar.svg"
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 text-left">
                          <p className="text-sm font-bold text-gray-900 ">
                          {userInfo.data.first_name} {userInfo.data.last_name}
                          </p>
                          <p className=" text-gray-500 font-light text-xs capitalize cursor-pointer">
                            Partner
                          </p>
                        </div>
                    </div>
                    <svg
                      className="h-5 w-5 flex-none text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>


                {/* <!-- Dropdown menu --> */}
                <div className="absolute -right-2 z-[100] w-48 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl">
                  <button
                    onClick={logoutHandler}
                    className="w-full flex items-center justify-center px-4 py-2 text-sm text-[#000AFF] capitalize transition-colors duration-300 transform  hover:bg-[#9FA2B4]/10 hover:text-[#0F45C3]  rounded-lg"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3188_3879"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="25"
                      >
                        <rect
                          y="0.5"
                          width="24"
                          height="24"
                          fill="currentColor"
                        />
                      </mask>

                      <path
                        d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5V5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5V21.5ZM16 17.5L14.6 16.075L17.175 13.5H9V11.5H17.175L14.6 8.9L16 7.5L21 12.5L16 17.5Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span className=" text-sm font-normal">Logout</span>
                  </button>
                </div>
              </div>
            </div>
          </nav>

         {/* <!-- Mobile menu **************************  */}
         <div className="lg:hidden px-3 py-2 bg-[#0F45C3] ">
            <div className="items-center  flex gap-x-3 ">
              <div className="w-full flex py-3 justify-between">
              <div className="flex items-center space-x-2">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-lg"
                          src="../Avatar.svg"
                          alt=""
                        />
                      </div>
                      <div className="min-w-0 text-left">
                      <p className=" text-[#acecf0] font-normal text-sm capitalize cursor-pointer">
                         Welcome Back
                          </p>
                          <p className="text-base font-bold text-white ">
                          {userInfo.data.first_name} {userInfo.data.last_name}
                          </p>
                          
                        </div>
                    </div>
                {toggle ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(false);
                    }}
                    className="text-[#acecf0]  hover:text-[#acecf0]   focus:outline-none focus:text-[#acecf0]  "
                    aria-label="toggle menu"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(true);
                    }}
                    className="text-[#acecf0]  hover:text-[#acecf0]   focus:outline-none focus:text-[#acecf0]"
                    aria-label="toggle menu"
                  >
                    <svg
                      className="w-6 h-auto"
                      width="30"
                      height="26"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="6" rx="3" fill="currentColor" />
                      <rect
                        y="10"
                        width="30"
                        height="6"
                        rx="3"
                        fill="currentColor"
                      />
                      <rect
                        y="20"
                        width="15"
                        height="6"
                        rx="3"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                )}
              </div>
              
            </div>

           
          </div>

          {/* toggle opaq backgroud */}
          <div
            onClick={(e) => {
              e.preventDefault();
              setToggle(false);
            }}
            className={`${
              toggle
                ? "translate-x-0 opacity-100 "
                : "opacity-0 -translate-x-full"
            } lg:hidden absolute inset-x-0 z-20 w-full top-0 h-screen transition-all duration-300 ease-in-out bg-[#0F45C3]/50 `}
          ></div>
          <div
            className={`${
              toggle
                ? "translate-x-0 opacity-100 "
                : "opacity-0 -translate-x-full"
            } lg:hidden absolute inset-x-0 z-20 w-[16rem] top-0 py-8 px-6 transition-all duration-300 ease-in-out bg-[#0F45C3]  space-y-3`}
          >
            <div className="flex flex-col justify-between  pb-14">
              <div className=" border-b border-dashed border-gray-200  w-full  mb-5 pb-2">
                <div className="relative w-full inline-block group">
                  {userInfo && (
                    <button
                      type="button"
                      className="flex items-center gap-x-2 text-sm font-bold leading-6  py-2.5  rounded-xl"
                      aria-expanded="false"
                    >
                      <div className="flex items-center space-x-2">
                        <div className="flex-shrink-0">
                          <img
                            className="h-8 w-8 rounded-lg"
                            src="../Avatar.svg"
                            alt=""
                          />
                        </div>
                        <div className="min-w-0">
                          <p className="text-sm font-bold text-gray-100 text-left">
                          {userInfo.data.first_name}  {userInfo.data.last_name}
                          </p>
                          <p className=" text-gray-100 font-light text-xs capitalize cursor-pointer  text-left">
                          Partner
                          </p>
                        </div>
                      </div>
                      <svg
                        className="h-5 w-5 flex-none text-gray-100"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  )}

                  {/* <!-- Dropdown menu --> */}
                  <div className="absolute -right-0 z-[100] w-48 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl">
                    <button
                      onClick={logoutHandler}
                      className="w-full flex items-center justify-center px-4 py-2 text-sm text-red-500 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3188_3879"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="25"
                        >
                          <rect
                            y="0.5"
                            width="24"
                            height="24"
                            fill="currentColor"
                          />
                        </mask>

                        <path
                          d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5V5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5V21.5ZM16 17.5L14.6 16.075L17.175 13.5H9V11.5H17.175L14.6 8.9L16 7.5L21 12.5L16 17.5Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className=" text-sm font-normal">Logout</span>
                    </button>
                  </div>
                </div>
              </div>

              <ul className=" -space-y-1 tracking-wide h-screen inset-y-0 overflow-y-auto scrollbar-hide">
              
             <li className="min-w-max px-2 hover:border-l-2">
              <NavLink
                to="/dashboard/index"
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(false);
                  navigate("/dashboard/index");
                }}
                className={({ isActive }) =>
                  isActive
                    ? "flex w-full items-center px-3 py-3 text-base font-bold text-[#0F45C3] capitalize transition-colors duration-300 transform  bg-[#E4E4E4] border-l-2 space-x-2 rounded-full"
                    : "flex w-full items-center px-3 py-3 text-base font-normal text-[#E4E4E4] capitalize transition-colors duration-300 transform  hover:bg-[#E4E4E4] hover:text-[#0F45C3] space-x-2   rounded-full"
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6">
                    <path
                      d="M14.6198 1.93606C12.9917 1.02131 11.0083 1.02131 9.38018 1.93606L3.34356 5.32762C1.88548 6.14682 1.88548 8.25428 3.34356 9.07348L9.38018 12.465C11.0083 13.3798 12.9917 13.3798 14.6198 12.465L20.6564 9.07348C22.1145 8.25428 22.1145 6.14682 20.6564 5.32762L14.6198 1.93606Z"
                      fill="currentColor"
                    />
                    <path
                      d="M2.97517 15.3369C1.89805 16.2769 2.02084 18.0802 3.34356 18.8234L9.38018 22.2149C11.0083 23.1297 12.9917 23.1297 14.6198 22.2149L20.6564 18.8234C21.9792 18.0802 22.102 16.2769 21.0248 15.3369L15.3545 18.5227C13.2701 19.6938 10.7299 19.6938 8.64545 18.5227L2.97517 15.3369Z"
                      fill="currentColor"
                    />
                    <path
                      d="M2.81218 10.4954C1.91468 11.4673 2.0918 13.1201 3.34356 13.8234L9.38018 17.2149C11.0083 18.1297 12.9917 18.1297 14.6198 17.2149L20.6564 13.8234C21.9082 13.1201 22.0853 11.4673 21.1878 10.4954L15.3545 13.7727C13.2701 14.9438 10.7299 14.9438 8.64545 13.7727L2.81218 10.4954Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <span className="text-sm font-medium">Home</span>
              </NavLink>
            </li>
            <li className="min-w-max px-2 hover:border-l-2 ">
              <NavLink
                to="/dashboard/account"
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(false);
                  navigate("/dashboard/account");
                }}
                className={({ isActive }) =>
                  isActive
                    ? "flex w-full items-center px-3 py-3 text-base font-bold text-[#0F45C3] capitalize transition-colors duration-300 transform  bg-[#E4E4E4] border-l-2 space-x-2 rounded-full"
                    : "flex w-full items-center px-3 py-3 text-base font-normal text-[#E4E4E4] capitalize transition-colors duration-300 transform  hover:bg-[#E4E4E4] hover:text-[#0F45C3] space-x-2  rounded-full " 
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6">
                    <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>

                <span className=" text-sm font-normal">My Account</span>
              </NavLink>
            </li>
              </ul>
            </div>
          </div>
        </header>
        <Outlet />
      </main>

      {openModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Session TimeOut
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Your session is about to expire. You'll be automatically signed
                out.
              </p>
              <p className="text-left pb-5 text-sm text-[#141414]/70">
                Do you want to stay signed in?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={() => {
                    handleOnTimeout();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Log out now
                </button>
                <button
                  onClick={() => {
                    stay();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#545252]  px-3 py-4 rounded-lg "
                >
                  Stay signed in
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PartnersDashboardLayout;
