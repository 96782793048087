import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Faq = () => {
  // State to track which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // State to track if user clicked "See More"
  const [showAll, setShowAll] = useState(false);

  // Toggle the answer visibility based on the clicked FAQ index
  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Toggle the "See More" button
  const toggleSeeMore = () => {
    setShowAll(!showAll);
  };

  const faqData = [
    {
      question: "How can I donate to BOAF?",
      answer:
        "There are two primary ways to contribute to BOAF: through direct cash donations or by becoming a partner. You can simply donate any amount starting from ₦5,000. Donations can be made as a one-time payment or scheduled as weekly or monthly contributions.",
    },
    {
      question: "What does it mean to be a partner at BOAF?",
      answer:
        "As a partner, you make a donation of either ₦5,000, ₦12,500, ₦50,000, or ₦100,000. After your initial donation, you will promote BOAF and its causes to your friends and family. For each group of three friends who join and donate the same amount you donated, you will receive a reward corresponding to your donation level.",
    },
    {
      question: "What rewards do partners receive?",
      answer:
        "The rewards you can earn as a partner depend on the donation amount you initially contribute: If you donate ₦5,000, you earn ₦12,500 for every three friends who also donate ₦5,000. If you donate ₦12,500, you will earn ₦37,500 for every three friends who also donate ₦12,500. If you donate ₦50,000, you will earn ₦125,000 for every three friends who also donate ₦50,000. If you donate ₦100,000, you will earn ₦250,000 for every three friends who also donate ₦100,000.",
    },
    {
      question: "How does the promotion process work?",
      answer:
        "After your initial donation, you will receive a unique promotion link that you can share with your friends and family. When they click on your link, it will direct them to BOAF's donation page, where they will see the amount to donate based on what you contributed. This ensures that friends donate the same amount as you, helping you earn rewards.",
    },
    {
      question: "Are there any restrictions on whom I can promote to?",
      answer:
        "Yes, as a partner, the amount you donated determines the donation amounts of the friends you promote. For instance, if you donated ₦50,000, you must encourage your friends to contribute ₦50,000 through your unique promotion link to qualify for the ₦125,000 reward.",
    },
    {
      question: "What is the partnership cycle?",
      answer:
        "The partnership cycle is a process where you donate and then promote BOAF to three individuals who donate the same amount as you did. Once you successfully have three friends donate the same amount, you receive your reward, completing the cycle. You can then repeat this cycle as many times as you wish, enabling you to earn multiple rewards by continuously donating and promoting the NGO to three new friends each time.",
    },
    {
      question: "Can I donate and participate as a partner multiple times?",
      answer:
        "Yes! You can donate as many times as you like and participate in the partnership cycle repeatedly. Each time you donate and bring in three friends who contribute the same amount, you can earn the associated rewards, creating ongoing opportunities to support BOAF and benefit from your contributions.",
    },
    {
      question: "How can I track my donations and rewards?",
      answer:
        "As a partner, you will have a wallet on your dashboard where you can easily track your rewards and contributions. This feature allows you to monitor your earnings and see the impact of your promotional efforts.",
    },
    {
      question:
        "What happens if my friends do not donate the same amount I did?",
      answer:
        "If your friends choose to donate a different amount, that contribution will not qualify you for the reward associated with your initial donation. To earn your reward, all three friends must donate the same amount that you initially contributed.",
    },
    {
      question:
        "How can I get more information about being a partner or the donation process?",
      answer:
        "For more details on how to become a partner, promote BOAF, or any other inquiries, please contact us through our website or customer service channels. You can also visit [boafcommunity.com/donation](https://boafcommunity.com/donation) for additional information and to make your donations. We are here to help you make a meaningful impact!",
    },
  ];

  return (
    <div className="bg-[#00CED21A] py-6 lg:py-12 w-full">
      <div className="lg:max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center ">
        <p className="text-lg font-semibold text-gray-700 mb-4">
          Have any Question?
        </p>
        <h1 className="text-3xl font-bold text-[#212121] text-center mb-8">
          Frequently Asked Questions
        </h1>

        <div>
          {/* Show first 5 FAQs or all if showAll is true */}
          {faqData.slice(0, showAll ? faqData.length : 5).map((faq, index) => (
            <div
              key={index}
              className={`border-b border-gray-200 transition-all duration-300 lg:w-[1123px] p-4 lg:p-8 ${
                openIndex === index ? "bg-[#00CED2]" : "bg-white"
              }`}
            >
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => toggleAnswer(index)}
              >
                <p className="text-xl font-medium text-[#212121]">
                  {faq.question}
                </p>
                {openIndex === index ? (
                  <FaChevronUp size={18} />
                ) : (
                  <FaChevronDown size={18} />
                )}
              </div>
              {openIndex === index && (
                <p className="mt-2 text-gray-600 text-sm">{faq.answer}</p>
              )}
            </div>
          ))}

          {/* See More Button */}
          {!showAll && (
            <button
              onClick={toggleSeeMore}
              className="mt-4 text-[#00CED2] font-semibold text-sm"
            >
              See More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faq;
