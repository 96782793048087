import { apiOne, apiTwo } from "./apiSlice";

export const paymentServiceApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({


    getPaymentMethods: builder.query({
      query: () => ({
        url: `paymentService/payment/getPaymentMethods`,
      }),
    }),

  }),
});

export const { useGetPaymentMethodsQuery } = paymentServiceApiSlice;

export const authPaymentServiceApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `updateStatusORMood`,
        method: "POST",
        body: data,
      }),
    }),

    getPersonalInfo: builder.query({
      query: () => ({
        url: `hotel/hotelMerchants`,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const { useGetPersonalInfoQuery, useUpdateProfileMutation } =
  authPaymentServiceApiSlice;
