import React, { useEffect } from "react";
import Faq from "../components/faq/faq";
import Upcoming from "../components/upcoming/upcoming";
import Testimonial from "../components/testimonial/testimonial";
import banner from "../assets/images/boaf web banner 1.png";
import mobileBanner from "../assets/images/BoafMobile.png";
import Initiatives from "../components/initiatives/initiatives";
import LearnMore from "../components/learn/learn";
import Involve from "../components/involve/involve";
import Community from "../components/community/community";
import Gallery from "../components/gallery/gallery";
import SEO from "../components/SEO";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="">
      <SEO
        title="Home"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />
      <img src={banner} alt="banner" className="w-full hidden md:block" />
      <img src={mobileBanner} alt="banner" className="w-full md:hidden" />
      <div className="flex flex-col space-y-4 items-center justify-center mt-4">
        <LearnMore />
        <Initiatives />
        <Involve />

        <Upcoming />
        <Testimonial />

        <Gallery />
        <Faq />
        <Community />
      </div>
    </div>
  );
};

export default Home;
