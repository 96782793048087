export function formatNumberAsCurrency(num) {
    if (typeof num === "string") {
        num = Number(num);
        // console.log(num);
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatNumber(num) {
    if (typeof num === "string") {
        num = Number(num);
    }
    if (num === undefined) {
        num = 0;
    }

    if (num % 1 === 0) {
        return num.toLocaleString();
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}