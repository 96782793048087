import React, { useState, useRef } from "react";
import SEO from "../../components/SEO";
import { useSelector } from "react-redux";
import ShareLink from "../../components/account/ShareLink";
import ProfileSetting from "../../components/account/ProfileSetting";
import BankSetting from "../../components/account/BankSetting";
import ChangePassword from "../../components/account/ChangePassword";

function Account() {
  const { userInfo } = useSelector((state) => state.auth);
  const [tab, setTab] = useState(1);

  // Create a ref for the ShareLink section
  const shareLinkRef = useRef(null);

  // Function to scroll to the ShareLink section
  const scrollToShareLink = () => {
    if (shareLinkRef.current) {
      shareLinkRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setTab(1); // Ensure the ShareLink tab is active
    }
  };

  return (
    <div className="p-2 lg:p-8 space-y-4">
      <SEO
        title="Account"
        description="At BOAF, we are committed to helping underserved communities."
        name="BOAF community"
        type="description"
      />

      <div className="shadow-xl rounded-3xl max-w-4xl bg-gradient-to-br from-[#E5ECFA]/100 via-[#F2F6FF]/50 to-[#FFFFFF]/100">
        <div className="lg:flex justify-between">
          <div className="px-3 lg:py-10">
            <div className="hidden lg:flex gap-x-3 items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 lg:h-20 lg:w-20 rounded-lg"
                  src="../Avatar.svg"
                  alt=""
                />
              </div>
              <div className="min-w-0 text-left">
                <p className="text-sm lg:text-xl font-bold text-[#1C2E62] ">
                  {userInfo.data.first_name} {userInfo.data.last_name}
                </p>
                <p className=" text-gray-800  text-xs lg:text-sm font-semibold capitalize cursor-pointer">
                  Partner
                </p>
              </div>
            </div>
            <div className="space-y-3 py-10">
              <button
                className={`w-full flex justify-center items-center gap-x-3 py-3 px-5 text-[#252A34B2]  border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center ${
                  tab === 1
                    ? "bg-[#00CED2] text-white"
                    : "hover:bg-[#00CED2] hover:text-white   bg-[#F1F1F1]"
                }`}
                onClick={() => setTab(1)}
              >
                <svg
                  width="23"
                  height="26"
                  viewBox="0 0 23 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7832 16.125C16.6793 16.125 15.6647 16.5068 14.8638 17.1453L9.85957 14.0176C10.0078 13.3473 10.0078 12.6527 9.85957 11.9823L14.8638 8.85469C15.6647 9.49321 16.6793 9.875 17.7832 9.875C20.372 9.875 22.4707 7.77632 22.4707 5.1875C22.4707 2.59868 20.372 0.5 17.7832 0.5C15.1944 0.5 13.0957 2.59868 13.0957 5.1875C13.0957 5.53701 13.1343 5.87744 13.2068 6.20513L8.20264 9.33276C7.40171 8.69429 6.38711 8.3125 5.2832 8.3125C2.69438 8.3125 0.595703 10.4112 0.595703 13C0.595703 15.5888 2.69438 17.6875 5.2832 17.6875C6.38711 17.6875 7.40171 17.3057 8.20264 16.6672L13.2068 19.7949C13.1329 20.129 13.0956 20.4703 13.0957 20.8125C13.0957 23.4013 15.1944 25.5 17.7832 25.5C20.372 25.5 22.4707 23.4013 22.4707 20.8125C22.4707 18.2237 20.372 16.125 17.7832 16.125Z"
                    fill="#0F45C3"
                    fill-opacity="0.7"
                  />
                </svg>
                <span
                  className="lg:hidden cursor-pointer"
                  onClick={scrollToShareLink}
                >
                  Refer a Friend and Earn
                </span>
                <span className="hidden lg:block">
                  Refer a Friend to BOAF Community and Earn
                </span>
              </button>
              <button
                className={`w-full py-3 text-[#252A34B2]  border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center ${
                  tab === 2
                    ? "bg-[#00CED2] text-white"
                    : "hover:bg-[#00CED2] hover:text-white   bg-[#F1F1F1]"
                }`}
                onClick={() => setTab(2)}
              >
                My Account Setting
              </button>
              <button
                className={`w-full py-3 text-[#252A34B2]  border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center ${
                  tab === 3
                    ? "bg-[#00CED2] text-white"
                    : "hover:bg-[#00CED2] hover:text-white   bg-[#F1F1F1]"
                }`}
                onClick={() => setTab(3)}
              >
                My Bank Setting
              </button>
              <button
                className={`w-full py-3 text-[#252A34B2]  border border-gray-300 rounded-lg font-inter font-semibold text-base leading-7 text-center ${
                  tab === 4
                    ? "bg-[#00CED2] text-white"
                    : "hover:bg-[#00CED2] hover:text-white   bg-[#F1F1F1]"
                }`}
                onClick={() => setTab(4)}
              >
                Change Password
              </button>
            </div>
          </div>

          <div className="p-3">
            <div ref={shareLinkRef}>{tab === 1 && <ShareLink />}</div>
            {tab === 2 && <ProfileSetting />}
            {tab === 3 && <BankSetting />}
            {tab === 4 && <ChangePassword />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Account;
