import React, { useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../preloader/Spinner";
import { toast } from "react-toastify";
import { useApi } from "../../context/ApiContext";

function ChangePassword() {
  const { userInfo } = useSelector((state) => state.auth);
  const { postData } = useApi();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Prepare payload
      const payload = {
        email: userInfo.data.email,
        currentPassword,
        newPassword,
      };

      console.log(payload);

      // Make the POST request via context
      const response = await postData(
        "/userService/user/dashboard/changePasssword",
        payload
      );

      console.log("Update submitted successfully:", response);
      setIsLoading(false);
      toast.success(response?.data?.message || response.message);
    } catch (err) {
      toast.error(err?.data?.message || err.message);
      setIsLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 px-4 pb-10  transition-all transform bg-white rounded-2xl shadow-3xl w-full sm:max-w-md sm:px-6 md:px-8  "
    >
      <hr className="h-2 rounded-full bg-[#00CED2] w-[9rem] mx-auto my-1" />

      <div>
        <label htmlFor="" className="text-left py-2 font-semibold">
          Current Password
        </label>
        <input
          type="text"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
          className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
        />
      </div>
      <div>
        <label htmlFor="" className="text-left py-2 font-semibold">
          New Password
        </label>
        <input
          type="text"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          className="w-full  px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00CED2]"
        />
      </div>
      <button
        className="w-full py-2 bg-[#00CED2] text-[#fff] rounded-[9px] font-semibold"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <span className="flex items-center justify-center gap-x-1">
            Change Password
          </span>
        )}
      </button>

      <hr className="w-full" />
    </form>
  );
}

export default ChangePassword;
